import React from 'react';
import './EnergyFlowOverview.css'; // CSS pro tuto stránku

export default function EnergyFlowOverview() {
  return (
    <div className="energy-flow-overview">

<div className="grid-container">
  {/* Distribuční soustava EG.D */}
  <div className="grid-item">
    <h2>Distribuční soustava EG.D</h2>
    <p><span className="label">Výkon na L1:</span> <span className="value">7.5 kW</span></p>
    <p><span className="label">Výkon na L2:</span> <span className="value">8.2 kW</span></p>
    <p><span className="label">Výkon na L3:</span> <span className="value">7.6 kW</span></p>
    <p><span className="label">Dodávka do DS:</span> <span className="value">0 kW</span></p>
    <p><span className="label">Dodávka z DS:</span> <span className="value">23.3 kW</span></p>
  </div>

  {/* BESS IBG */}
  <div className="grid-item">
    <h2>BESS IBG</h2>
    <p><span className="label">Výkon na L1:</span> <span className="value">-0.2 kW</span></p>
    <p><span className="label">Výkon na L2:</span> <span className="value">0.1 kW</span></p>
    <p><span className="label">Výkon na L3:</span> <span className="value">-0.8 kW</span></p>
    <p><span className="label">Celkový výkon:</span> <span className="value">-0.6 kW</span></p>
    <p><span className="label">Nabití baterie:</span> <span className="value">78%</span></p>
  </div>

  {/* Fotovoltaická elektrárna */}
  <div className="grid-item">
    <h2>Fotovoltaická elektrárna</h2>
    <p><span className="label">Výkon na L1:</span> <span className="value">0 kW</span></p>
    <p><span className="label">Výkon na L2:</span> <span className="value">0 kW</span></p>
    <p><span className="label">Výkon na L3:</span> <span className="value">0 kW</span></p>
    <p><span className="label">Celkový výkon:</span> <span className="value">0 kW</span></p>
    <p><span className="label">Aktuální regulace:</span> <span className="value">0%</span></p>
  </div>

  {/* Hlavní rozvodna areálu */}
  <div className="grid-item">
    <h2>Hlavní rozvodna areálu</h2>
    <p><span className="label">Výkon na L1:</span> <span className="value">7.3 kW</span></p>
    <p><span className="label">Výkon na L2:</span> <span className="value">8.7 kW</span></p>
    <p><span className="label">Výkon na L3:</span> <span className="value">6.8 kW</span></p>
    <p><span className="label">Celkový příkon:</span> <span className="value">22.9 kW</span></p>
    <p><span className="label">Stupeň soběstačnosti:</span> <span className="value">0%</span></p>
  </div>

  {/* Spotřeba z DS */}
  <div className="grid-item">
    <h2>Spotřeba z DS</h2>
    <p><span className="label">Spotřeba dnes:</span> <span className="value">254.2 kWh</span></p>
    <p><span className="label">Spotřeba za měsíc:</span> <span className="value">6.4 MWh</span></p>
    <p><span className="label">Spotřeba za rok:</span> <span className="value">18.43 MWh</span></p>
    <p><span className="label">Spotřeba od spuštění:</span> <span className="value">18.43 MWh</span></p>
  </div>

  {/* Import do BESS */}
  <div className="grid-item">
    <h2>Import do BESS</h2>
    <p><span className="label">Import dnes:</span> <span className="value">21.7 kWh</span></p>
    <p><span className="label">Import za měsíc:</span> <span className="value">0.61 MWh</span></p>
    <p><span className="label">Import za rok:</span> <span className="value">2.75 MWh</span></p>
    <p><span className="label">Import od spuštění:</span> <span className="value">2.75 MWh</span></p>
  </div>

  {/* Export z BESS */}
  <div className="grid-item">
    <h2>Export z BESS</h2>
    <p><span className="label">Export dnes:</span> <span className="value">8.1 kWh</span></p>
    <p><span className="label">Export za měsíc:</span> <span className="value">1.48 MWh</span></p>
    <p><span className="label">Export za rok:</span> <span className="value">1.49 MWh</span></p>
    <p><span className="label">Export od spuštění:</span> <span className="value">1.49 MWh</span></p>
  </div>

  {/* Výroba FVE */}
  <div className="grid-item">
    <h2>Výroba FVE</h2>
    <p><span className="label">Výroba dnes:</span> <span className="value">71.1 kWh</span></p>
    <p><span className="label">Výroba za měsíc:</span> <span className="value">2.06 MWh</span></p>
    <p><span className="label">Výroba za rok:</span> <span className="value">8 MWh</span></p>
    <p><span className="label">Výroba od spuštění:</span> <span className="value">8 MWh</span></p>
  </div>

  {/* Spotřeba areálu */}
  <div className="grid-item">
    <h2>Spotřeba areálu</h2>
    <p><span className="label">Spotřeba dnes:</span> <span className="value">330.4 kWh</span></p>
    <p><span className="label">Spotřeba za měsíc:</span> <span className="value">8.26 MWh</span></p>
    <p><span className="label">Spotřeba za rok:</span> <span className="value">25.45 MWh</span></p>
    <p><span className="label">Spotřeba od spuštění:</span> <span className="value">25.45 MWh</span></p>
  </div>
</div>
      </div>
  );
}
