import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function LoginPage({ onLogin }) {
  const [passwordVisible, setPasswordVisible] = useState(false); // Stav pro viditelnost hesla

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    if (username === 'admin' && password === 'password123') {
      onLogin();
    } else {
      alert('Špatné uživatelské jméno nebo heslo');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div 
      className="login-container" 
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.png)`,
      }}
    >
      <div className="login-logo-container">
        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Metrica Logo" className="login-logo" />
      </div>
      <div className="login-box">
        <h2>PŘÍSTUP K INSTALACI:</h2>
        <h1>MALÉ SAPELI</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Uživatelské jméno:</label>
            <input type="text" name="username" id="username" required />
            <FontAwesomeIcon icon={faUser} className="icon" />
          </div>
          <div className="input-group">
            <label htmlFor="password">Heslo:</label>
            <input 
              type={passwordVisible ? "text" : "password"} 
              name="password" 
              id="password" 
              required 
            />
            <FontAwesomeIcon icon={faLock} className="icon" />
            <FontAwesomeIcon 
              icon={passwordVisible ? faEyeSlash : faEye} 
              className="toggle-password" 
              onClick={togglePasswordVisibility} 
            />
          </div>
          <div className="button-group">
            <button type="button">Zapomenuté heslo</button>
            <button type="submit">Přihlásit se</button>
          </div>
        </form>
      </div>
    </div>
  );
}
