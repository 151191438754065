import React from 'react';
import './BESSOverview.css';

const BESSOverview = () => {
  return (
    <div className="bess-overview">
      <h1 className="section-title">BATERIOVÝ SYSTÉM (BESS IGB):</h1>

      <div className="bess-container">
        <div className="bess-image">
          <img src="/images/ibg-LGCHEM.png" alt="Bateriový systém" />
        </div>

        <div className="bess-status">
          <div className="status-box">
            <h2>STATUS BESS:</h2>
            <div className="status-value">Vypnuto</div>
          </div>

          <div className="power-box">
            <h3>VÝKON NABÍJENÍ BESS:</h3>
            <p>0 kW</p>
          </div>

          <div className="power-box">
            <h3>VÝKON VYBÍJENÍ BESS:</h3>
            <p>0 kW</p>
          </div>

          <div className="power-box">
            <h3>VLASTNÍ SPOTŘEBA BESS:</h3>
            <p>0 kW</p>
          </div>
        </div>

        <div className="bess-details">
  <div className="power-box">
    <h3>MĚNIČ BESS:</h3>
    <p>VÝKON měniče L1: 0 kW</p>
    <p>VÝKON měniče L2: 0 kW</p>
    <p>VÝKON měniče L3: 0 kW</p>
    <p>CELKOVÝ VÝKON měniče: 0 kW</p>
    <p>JALOVÝ VÝKON měniče: 0 kVAR</p>
    <p>PROUD měniče – fáze L1: 0 A</p>
    <p>PROUD měniče – fáze L2: 0 A</p>
    <p>PROUD měniče – fáze L3: 0 A</p>
  </div>
  
  <div className="detail-box">
    <h2>CYKLY BESS:</h2>
    <p>Počet cyklů dnes: 0</p>
    <p>Počet cyklů celkem: 2.03</p>
    <p>Zbývající počet cyklů (záruka): 2998</p>
    <h2>BATERIOVÉ ULOŽIŠTĚ:</h2>
    <p>NAPĚTÍ článků (součet): 742 V</p>
    <p>PROUD článků: 0.3 A</p>
    <p>VÝKON článků: -38 kW</p>
    <p>NABITÍ baterie: 78 %</p>
    <p>TEPLOTA baterie: 11.8 °C</p>
  </div>
</div>
      </div>

      <div className="battery-status">
        <h2>STAV NABITÍ BATERIOVÉHO SYSTÉMU:</h2>
        <div className="battery-meter">
          <div className="battery-fill" style={{ width: '78%' }}></div>
        </div>
        <div className="battery-info">
          <span>NABITÍ BESS V %:</span>
          <strong>78 %</strong>
          <strong>234 kWh</strong>
        </div>
      </div>
    </div>
  );
};

export default BESSOverview;
