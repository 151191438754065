import React from 'react';

const Statistics = () => {
  return (
    <div>
      {/* Tvůj obsah pro stránku */}
    </div>
  );
};

export default Statistics;
