import React from 'react';

const SpotMarket = () => {
  return (
    <div>
      {/* Tvůj obsah pro stránku */}
    </div>
  );
};

export default SpotMarket;
