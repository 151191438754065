import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import './SpotPrices.css';
import Menu from './Menu';  // Načtení menu

// Funkce pro barvy
const getBarColor = (price) => {
  if (price < 20) return '#FF0000';
  if (price >= 20 && price <= 80) {
    const ratio = (price - 20) / 60;
    const green = Math.min(255, Math.floor(255 * ratio));
    const red = Math.max(0, 255 - Math.floor(255 * ratio * 2));
    return `rgb(${red},${green},0)`;
  }
  return '#00FF00';
};

// Data
const hourlyData = [
  { time: '0:00', nextTime: '1:00', price: 22.29, fill: getBarColor(22.29) },
  { time: '1:00', nextTime: '2:00', price: 20.85, fill: getBarColor(20.85) },
  { time: '2:00', nextTime: '3:00', price: 19.41, fill: getBarColor(19.41) },
  { time: '3:00', nextTime: '4:00', price: 18.07, fill: getBarColor(18.07) },
  { time: '4:00', nextTime: '5:00', price: 18.94, fill: getBarColor(18.94) },
  { time: '5:00', nextTime: '6:00', price: 21.62, fill: getBarColor(21.62) },
  { time: '6:00', nextTime: '7:00', price: 27.44, fill: getBarColor(27.44) },
  { time: '7:00', nextTime: '8:00', price: 35.35, fill: getBarColor(35.35) },
  { time: '8:00', nextTime: '9:00', price: 36.61, fill: getBarColor(36.61) },
  { time: '9:00', nextTime: '10:00', price: 34.49, fill: getBarColor(34.49) },
  { time: '10:00', nextTime: '11:00', price: 33.37, fill: getBarColor(33.37) },
  { time: '11:00', nextTime: '12:00', price: 31.35, fill: getBarColor(31.35) },
  { time: '12:00', nextTime: '13:00', price: 29.94, fill: getBarColor(29.94) },
  { time: '13:00', nextTime: '14:00', price: 32.42, fill: getBarColor(32.42) },
  { time: '14:00', nextTime: '15:00', price: 34.53, fill: getBarColor(34.53) },
  { time: '15:00', nextTime: '16:00', price: 87.88, fill: getBarColor(87.88) },
  { time: '16:00', nextTime: '17:00', price: 38.96, fill: getBarColor(38.96) },
  { time: '17:00', nextTime: '18:00', price: 41.64, fill: getBarColor(41.64) },
  { time: '18:00', nextTime: '19:00', price: 40.22, fill: getBarColor(40.22) },
  { time: '19:00', nextTime: '20:00', price: 37.41, fill: getBarColor(37.41) },
  { time: '20:00', nextTime: '21:00', price: 32.62, fill: getBarColor(32.62) },
  { time: '21:00', nextTime: '22:00', price: 31.69, fill: getBarColor(31.69) },
  { time: '22:00', nextTime: '23:00', price: 32.12, fill: getBarColor(32.12) },
  { time: '23:00', nextTime: '24:00', price: 26.90, fill: getBarColor(26.90) },
];

function SpotPrices() {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="main-container">
      <Menu />  {/* Přidání Menu komponenty */}
      <div className="content-area">
        {/* Odstranění duplikované hlavičky a loga */}
        <div className="spot-prices-container">
          <div className="chart-header">
            <span>{currentDate}</span>
          </div>
          <div className="hourly-chart">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={hourlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tickFormatter={(time, index) => `${time}\n${hourlyData[index].nextTime}`} />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Bar dataKey="price" fill={({ payload }) => payload.fill} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpotPrices;
