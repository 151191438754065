import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import SpotPrices from './components/SpotPrices';
import Menu from './components/Menu';
import MQTTDataDisplay from './components/MQTTDataDisplay';
import LoginPage from './components/LoginPage'; 
import EnergyFlowOverview from './components/EnergyFlowOverview';
import FVEOverview from './components/FVEOverview';
import BESSOverview from './components/BESSOverview';
import Dashboard from './components/Dashboard';
import SpotMarket from './components/SpotMarket';
import Statistics from './components/Statistics';
import Home from './components/Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'; // Import konkrétní ikony

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem('isAuthenticated');
    if (loggedIn) {
      setIsAuthenticated(true);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('isAuthenticated', 'true');
  };

  return (
    <Router>
      <div className="main-container">
        {!isAuthenticated ? (
          <LoginPage onLogin={handleLogin} />
        ) : (
          <>
            <Menu isOpen={menuOpen} toggleMenu={toggleMenu} />
            <div className="content-area">
              <header className="app-header">
                <div className="logo-container">
                  <Link to="/">
                    <img src="/images/LOGO-METRICA_white.png" alt="Metrica Logo" className="metrica-logo" />
                  </Link>
                </div>
                <div className="site-title-container">
                  <div className="site-title">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
                    SAPELI Group, a.s. - areál PÁVOVSKÁ
                  </div>
                </div>
              </header>

              <MQTTDataDisplay />

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<EnergyFlowOverview />} /> {/* Přehled energetických toků */}
                <Route path="/fve" element={<FVEOverview />} /> {/* Měřící body instalace */}
                <Route path="/bess" element={<BESSOverview />} /> {/* Přehled BESS */}
                <Route path="/dashboard-overview" element={<Dashboard />} /> {/* Dashboardy */}
                <Route path="/spot-market" element={<SpotMarket />} /> {/* Spotový trh OTE */}
                <Route path="/statistics" element={<Statistics />} /> {/* Statistiky */}
                <Route path="/spot-prices" element={<SpotPrices />} />
              </Routes>

              <footer className="footer">
                © 2024 METRICA / YOUNG4SERVICES s.r.o. | Powered by YOUNG4ENERGY s.r.o.
                <div className="version">Verze aplikace: 1.0</div>
              </footer>
            </div>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
