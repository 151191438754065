import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home-page">
      <div className="image-container">
        <img src="/images/male_sapeli_prehled.png" alt="Sapeli Areál" className="site-image" />

        {/* Přidání obdélníčku pro FVE */}
        <div className="info-box-home fve-box">
          <div className="info-content">
            <img src="/images/FVE.png" alt="FVE" className="icon" />
            <div>
              <h3>FVE O VÝKONU 138,18 kWp</h3>
              <p>VÝKON: 8.1 kW</p>
            </div>
          </div>
          <div className="info-arrow"></div>
        </div>

        {/* Přidání obdélníčku pro DS */}
        <div className="info-box-home distr-box">
          <div className="info-content">
            <img src="/images/DS.png" alt="Distributor" className="icon" />
            <div>
              <h3>DISTRIBUČNÍ SOUSTAVA</h3>
              <p>PŘÍKON: 8.1 kW</p>
            </div>
          </div>
          <div className="info-arrow"></div>
        </div>

        {/* Přidání obdélníčku pro BESS */}
        <div className="info-box-home bess-box">
          <div className="info-content">
            <img src="/images/BESS.png" alt="BESS" className="icon" />
            <div>
              <h3>BESS O KAPACITĚ 300 kWh</h3>
              <p>VÝKON: -0.6 kW</p>
            </div>
          </div>
          <div className="info-arrow"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
